import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

/**
 * Directiva que renderiza un icono.
 */
@Directive({
  selector: '[appIcon]',
  standalone: true
})
export class IconDirective implements OnChanges {
  private baseClass = 'pi';

  /** icono */
  @Input() icon!: string;
  /** indica si el icono es clickeable */
  @Input() clickable = false;
  /** tamaño del icono (opcional: 'lg', '2x', '3x', etc.) */
  @Input() size?: string;

  @HostBinding('class') elementClass = this.baseClass;
  @HostBinding('style') elementStyle?: Record<string, string>;
  style?: Record<string, string>; // Define the style property

  /**
   * Detecta cambios en las propiedades de la directiva.
   * @param changes cambios detectados.
   */
  ngOnChanges(changes: SimpleChanges) {
    const icon = changes['icon']
      ? (changes['icon'].currentValue as string)
      : this.icon;
    const clickable = changes['clickable']
      ? (changes['clickable'].currentValue as boolean)
      : this.clickable;
    const size = changes['size']
      ? (changes['size'].currentValue as string)
      : this.size;

    this.elementClass = `${this.baseClass} pi-${icon}`;
    this.elementClass += clickable ? ' app-icon-clickable' : '';
    this.elementClass += size ? ` pi-5x` : '';

    this.elementStyle = this.style ? { ...this.style } : {};
  }
}
